import React, {createContext, useState} from "react"

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
    const isLoggedInSessionValue = sessionStorage.getItem('isLoggedIn');
    const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInSessionValue);

    const logoutHandler = () => {
        setIsLoggedIn(false);
        sessionStorage.removeItem('isLoggedIn');
    };

    const loginHandler = () => {
        setIsLoggedIn(true);
        sessionStorage.setItem('isLoggedIn', true);
    };


    return (
        <AuthContext.Provider value={{isLoggedIn: isLoggedIn, loginHandler: loginHandler, logoutHandler: logoutHandler}}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;