import React, { Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Spinner from './Components/Common/LoadingSpinner/Spinner';
import Lending from './Components/Login/Lending';

import './App.css';
import AuthContext from './Store/auth-context';

const Login = React.lazy(() => import('./Components/Login/CredentialsFolder/Login'));
const Pages = React.lazy(() => import('./Components/Pages/Pages'));
const LeadStatus = React.lazy(() => import('./Components/Pages/Outletpages/LeadStatus/LeadStatus'));
const Disbursement = React.lazy(() => import('./Components/Pages/Outletpages/Disbursement/Disbursement'));
const Home = React.lazy(()=>import('./Components/Pages/Outletpages/Home/Home'));
const UserLoanStatus = React.lazy(()=>import('./Components/Pages/Outletpages/Disbursement/UserLoanStatus/UserLoanStatus'));
const UserLeadStatus = React.lazy(()=>import('./Components/Pages/Outletpages/LeadStatus/UserLeadStatus/UserLeadStatus'));

function App() {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.isLoggedIn;

  window.addEventListener('beforeunload', () => {
    // sessionStorage.removeItem('leadDetails');
    // sessionStorage.removeItem('fromDate');
    // sessionStorage.removeItem('todayDate');
  });
  
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {isLoggedIn ?
          <Route path="" element={<Pages />}>
            <Route path="/lead" element={<LeadStatus />} />
            <Route path="/disbursement" element={<Disbursement />} />
            <Route path="/disbursement/status/:fileId" element={<UserLoanStatus />} />
            <Route path="/lead/status/:fileId" element={<UserLeadStatus />} />
            <Route path='' element={<Home />} />
          </Route>
          :
          <Route path="" element={<Lending />}>
            <Route path="" element={<Login />} />
          </Route>
        }
        <Route to='*' element={<Navigate to='/'/>} />
      </Routes>
    </Suspense>
  );
}

export default App;
