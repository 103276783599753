import { Fragment } from "react";

import './Spinner.scss';

const Spinner = ()=> {
    return (
        <Fragment>
            <div className="wrapper-container">
                <div className="wrapper">
                    <div className="letters-container">
                    <span>L</span>
                    <span>X</span>
                    <span>M</span>
                    <span>E</span>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Spinner;